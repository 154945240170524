// src/helpers/BatchUploadHandler.js
export class BatchUploadHandler {
	constructor(config = {}) {
		this.maxConcurrent = config.maxConcurrent || 4;
		this.maxRetries = config.maxRetries || 3;
		this.retryDelay = config.retryDelay || 2000;
		this.activeUploads = new Set();
		this.failedUploads = new Map();
		this.pendingUploads = [];
		this.onProgress = () => {};
		this.isCancelled = false;
	}

	setProgressCallback(callback) {
		this.onProgress = callback;
	}

	cancel() {
		this.isCancelled = true;
	}

	addToQueue(uploadTask) {
		this.pendingUploads.push(uploadTask);
	}

	async processWithRetry(task, retryCount = 0) {
		try {
			const result = await task.uploadFn();
			this.activeUploads.delete(task.id);
			this.onProgress();
			return result;
		} catch (error) {
			console.error(`Upload failed for ${task.file.name}:`, error);

			if (retryCount < this.maxRetries && !this.isCancelled) {
				const delay = this.retryDelay * Math.pow(2, retryCount);
				await new Promise((resolve) => setTimeout(resolve, delay));
				return this.processWithRetry(task, retryCount + 1);
			}

			this.failedUploads.set(task.id, {
				file: task.file,
				error: error.message || 'Upload failed after retries'
			});
			throw error;
		}
	}

	async uploadBatch() {
		const results = [];

		while (this.pendingUploads.length > 0 && !this.isCancelled) {
			while (this.activeUploads.size < this.maxConcurrent && this.pendingUploads.length > 0) {
				const task = this.pendingUploads.shift();
				this.activeUploads.add(task.id);

				this.processWithRetry(task)
					.then((result) => results.push(result))
					.catch(() => {
						this.activeUploads.delete(task.id);
					});
			}

			await new Promise((resolve) => setTimeout(resolve, 50));
		}

		// Wait for remaining active uploads
		while (this.activeUploads.size > 0) {
			await new Promise((resolve) => setTimeout(resolve, 50));
		}

		return {
			results,
			failed: Array.from(this.failedUploads.values())
		};
	}
}
