import { PlusOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Select,
	Switch
} from 'antd';
import React, { useEffect, useState } from 'react';
import { calculateActualUploadLimit } from '../utils/eventUtils';
import HostForm from './HostForm';

const { Option } = Select;

const EventForm = ({
	userId,
	subscriptionId,
	selectedPackId,
	createEvent,
	eventCreated,
	creationType,
	subscription,
	selectedPack
}) => {
	const [form] = Form.useForm();
	const [isGuestUploadEnabled, setIsGuestUploadEnabled] = useState(false);
	const [maxAllowedPhotos, setMaxAllowedPhotos] = useState(0);
	const [showSubscriptionError, setShowSubscriptionError] = useState(false);

	useEffect(() => {
		if (creationType === 'subscription' && subscription) {
			const limit = calculateActualUploadLimit(subscription);
			setMaxAllowedPhotos(limit);
			setShowSubscriptionError(limit < 1);
		} else if (creationType === 'pack' && selectedPack) {
			setMaxAllowedPhotos(selectedPack.photos);
			setShowSubscriptionError(false);
		}
	}, [creationType, subscription, selectedPack]);

	const validateGuestPhotoLimit = (_, value) => {
		if (value === null || value === undefined) {
			return Promise.reject('Please enter a number');
		}

		if (typeof value !== 'number' || !Number.isInteger(value)) {
			return Promise.reject('Please enter a valid integer value');
		}

		if (value <= 0) {
			return Promise.reject('Number must be greater than 0');
		}

		if (value > maxAllowedPhotos) {
			return Promise.reject(
				`Guest photo limit cannot exceed ${maxAllowedPhotos} photos (${
					creationType === 'subscription' ? 'subscription upload limit' : 'pack limit'
				})`
			);
		}

		return Promise.resolve();
	};

	const handleCreateEvent = async (values) => {
		// Check subscription limit before allowing event creation
		if (creationType === 'subscription' && maxAllowedPhotos < 1) {
			message.error('Cannot create event: Subscription limit exceeded');
			return;
		}

		const eventData = {
			name: values.eventName,
			EventType: creationType === 'subscription' ? 1 : 0,
			purchaseId: creationType === 'pack' ? selectedPackId : null,
			subscriptionId: creationType === 'subscription' ? subscriptionId : null,
			isGuestUploadEnabled,
			guestMaxPhotos: isGuestUploadEnabled ? values.guestMaxPhotos : null,
			category: values.category,
			eventDate: values.eventDate.toISOString().split('T')[0],
			allowEnquiry: values.allowEnquiry,
			hosts: values.hosts.filter((host) => host && host.name && host.email),
			isDownloadOff: values.isDownloadOff || false,
			isDownloadMyPhotosOff: values.isDownloadMyPhotosOff || false
		};

		try {
			await createEvent(userId, eventData);
			eventCreated();
		} catch (error) {
			message.error(error.message);
			console.error('Failed to create event:', error);
		}
	};

	if (showSubscriptionError && creationType === 'subscription') {
		return (
			<Alert
				message="Subscription Limit Exceeded"
				description={
					<span>
						You have used up your subscription limit. To top up, contact our Sales team at{' '}
						<a href="mailto:sales@kamero.in">sales@kamero.in</a> or{' '}
						<a href="tel:+918422088422">+91 8422 08 8422</a>.
					</span>
				}
				type="error"
				showIcon
				className="mb-4"
			/>
		);
	}

	return (
		<>
			{showSubscriptionError && (
				<Alert
					message="Subscription Limit Exceeded"
					description={
						<span>
							You have used up your subscription limit. To top up, contact our Sales team at{' '}
							<a href="mailto:sales@kamero.in">sales@kamero.in</a> or{' '}
							<a href="tel:+918422088422">+91 8422 08 8422</a>.
						</span>
					}
					type="error"
					showIcon
					className="mb-4"
				/>
			)}
			<Form
				form={form}
				onFinish={handleCreateEvent}
				layout="vertical"
				initialValues={{
					hosts: [{}],
					allowEnquiry: true,
					isDownloadOff: false,
					isDownloadMyPhotosOff: false
				}}
				disabled={showSubscriptionError && creationType === 'subscription'}>
				<Row gutter={16}>
					<Col xs={24} lg={24}>
						<Form.Item
							name="eventName"
							label="Event Name"
							tooltip="The name guests will see for your event."
							rules={[{ required: true, message: 'Please enter event name' }]}>
							<Input placeholder="The name guests will see for your event" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} lg={12}>
						<Form.Item
							name="category"
							label="Event Category"
							rules={[{ required: true, message: 'Please select event category' }]}>
							<Select placeholder="Select event category">
								{[
									'Wedding',
									'Engagement',
									'Birthday',
									'Corporate',
									'Couple Shoot',
									'Family Photoshoot',
									'Maternity Photoshoot',
									'Fashion Model Photoshoot',
									'Baby Photoshoot',
									'Product Photoshoot',
									'Real Estate Photoshoot',
									'Restaurant Photoshoot',
									'Themed Photoshoot',
									'Conference/Expo',
									'Other'
								].map((cat) => (
									<Option key={cat} value={cat}>
										{cat}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>

					<Col xs={24} lg={12}>
						<Form.Item
							name="eventDate"
							label="Event Date"
							tooltip="The actual date of the event; doesn't affect validity on Kamero."
							rules={[{ required: true, message: 'Please select event date' }]}>
							<DatePicker className="full-width" placeholder="Select event date" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<Row gutter={8} align="middle">
							<Col flex="auto">
								<Form.Item
									label="Enable Guest Upload"
									className="mb-0"
									tooltip="If you turn this on, app guests will be able to upload images that they captured during the event. You can set a maximum limit up to which guests will be able to upload images.">
									<Switch onChange={setIsGuestUploadEnabled} />
								</Form.Item>
							</Col>
							{isGuestUploadEnabled && (
								<Col flex="none">
									<Form.Item
										name="guestMaxPhotos"
										label="Max Photos For Guest Upload"
										tooltip={`Maximum number of photos each guest can upload (limit: ${maxAllowedPhotos})`}
										rules={[
											{ required: true, message: 'Please enter max photos' },
											{ validator: validateGuestPhotoLimit }
										]}
										className="mb-0">
										<InputNumber
											min={1}
											style={{ width: '100px' }}
											placeholder="Max photos"
											parser={(value) => {
												const parsed = parseInt(value, 10);
												return isNaN(parsed) ? null : parsed;
											}}
											formatter={(value) => value}
										/>
									</Form.Item>
								</Col>
							)}
						</Row>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item
							name="allowEnquiry"
							label="Allow Enquiry"
							tooltip="Allow event viewers to contact you about your products and services (generate leads)"
							valuePropName="checked"
							className="mb-0">
							<Switch defaultChecked />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<Row gutter={8} align="middle">
							<Form.Item name="isDownloadOff" valuePropName="checked">
								<Checkbox>Turn off photo downloads</Checkbox>
							</Form.Item>
						</Row>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item name="isDownloadMyPhotosOff" valuePropName="checked">
							<Checkbox>Turn off My Photos downloads</Checkbox>
						</Form.Item>
					</Col>
				</Row>

				<h3 className="mt-6">Host Details</h3>
				<p className="text-gray-500 mb-4">
					Enter host details for managing and accessing event photos.
				</p>

				<Form.List name="hosts">
					{(fields, { add, remove }) => (
						<>
							{fields.map((field) => (
								<HostForm
									key={field.key}
									name={field.name}
									remove={remove}
									tooltips={{
										name: "Enter the main organizer's name.",
										email: "Host's email for notifications and Photo Selection",
										mobile: "Host's 10-digit mobile number (no STD code)."
									}}
								/>
							))}
							{fields.length < 5 && (
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => add()}
										icon={<PlusOutlined />}
										className="add-host-button">
										Add Host
									</Button>
								</Form.Item>
							)}
						</>
					)}
				</Form.List>

				<Form.Item className="form-actions">
					<Button type="primary" htmlType="submit">
						Create Event
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default EventForm;
