import { Button, Checkbox, Input, InputNumber, message, Modal, Switch } from 'antd';
import { Download } from 'lucide-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { refreshVisibleEvent } from '../actions/pouchDBActions';
import Event from '../pouchDB/models/Event';

class EventSettings extends Component {
	constructor(props) {
		super(props);
		// Initialize state with props.event values
		const event = props.event;
		this.state = {
			localError: undefined,
			isPrivateFaces: event.isPrivateFaces,
			isRegistrationRequired: event.isRegistrationRequired,
			isGuestUploadEnabled: event.isGuestUploadEnabled,
			isEventDisabled: event.isEventDisabled,
			allowEnquiry: event.allowEnquiry !== undefined ? event.allowEnquiry : true,
			loading: false,
			shareDetail: undefined,
			email: '',
			emailInput: '',
			emailError: '',
			showDownloadForm: false,
			copied: 0,
			// Initialize download settings from event props
			isDownloadOff: event.isDownloadOff || false,
			isDownloadMyPhotosOff: event.isDownloadMyPhotosOff || false,
			newGuestMaxphotos: event.guestMaxPhotos
		};
	}

	async componentDidMount() {
		await this.fetchEventData();
		await this.fetchData();
	}

	error() {
		const { lastError } = this.props;
		return this.state.localError || lastError ? (
			<p style={{ color: '#FF7981', fontSize: '12px' }}>
				{lastError ? lastError : this.state.localError}
			</p>
		) : null;
	}

	fetchEventData = async () => {
		try {
			const eventData = await Event.fetchEvent(this.props.event.id);
			if (eventData) {
				this.setState({
					isDownloadOff: eventData.isDownloadOff || false,
					isDownloadMyPhotosOff: eventData.isDownloadMyPhotosOff || false
				});
			}
		} catch (err) {
			console.error('Error fetching event data:', err);
		}
	};

	fetchData = async () => {
		const { event, fetchShareDetail } = this.props;
		try {
			this.setState({ loading: true });
			let shareDetail = await fetchShareDetail(event.id);
			if (shareDetail) {
				this.setState({ loading: false, shareDetail: shareDetail });
			} else {
				this.setState({ loading: false });
			}
		} catch (err) {
			this.setState({ loading: false });
		}
	};

	handleEmailChange = (e) => {
		this.setState({ emailInput: e.target.value });
	};

	validateEmail = (email) => {
		const emailError = email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
			? ''
			: 'Invalid email address';
		this.setState({ email, emailError });
		return !emailError;
	};

	copyToClipBoard = (item, text) => {
		const textarea = document.createElement('textarea');
		document.body.appendChild(textarea);
		textarea.value = text;
		textarea.select();
		textarea.setSelectionRange(0, 99999);
		document.execCommand('copy');
		document.body.removeChild(textarea);
		this.setState({ copied: item });
		setTimeout(() => {
			this.setState({ copied: 0 });
		}, 3000);
	};

	handleSave = async () => {
		const { userId, event, onComplete, updateEventBoolKey, updateNewGuestMaxPhotos } = this.props;

		try {
			const updates = [
				{ key: 'isPrivateFaces', value: this.state.isPrivateFaces },
				{ key: 'isRegistrationRequired', value: this.state.isRegistrationRequired },
				{ key: 'isGuestUploadEnabled', value: this.state.isGuestUploadEnabled },
				{ key: 'isEventDisabled', value: this.state.isEventDisabled },
				{ key: 'allowEnquiry', value: this.state.allowEnquiry },
				{ key: 'isDownloadOff', value: this.state.isDownloadOff },
				{ key: 'isDownloadMyPhotosOff', value: this.state.isDownloadMyPhotosOff }
			];

			// Process all updates sequentially
			for (const update of updates) {
				if (event[update.key] !== update.value) {
					console.log(`Updating ${update.key} to ${update.value}`);
					await updateEventBoolKey(userId, event.id, update.key, update.value);
				}
			}

			// Handle guest max photos update
			if (
				this.state.isGuestUploadEnabled &&
				this.state.newGuestMaxphotos !== undefined &&
				this.state.newGuestMaxphotos !== event.guestMaxPhotos
			) {
				await updateNewGuestMaxPhotos(userId, event.id, this.state.newGuestMaxphotos);
			}

			onComplete(true);
		} catch (e) {
			console.error('Error saving settings:', e);
			onComplete(false);
			message.error(e?.response?.body?.message || 'Failed to update settings');
		}
	};
	render() {
		const {
			userId,
			event,
			onComplete,
			updateEventBoolKey,
			updateNewGuestMaxPhotos,
			downloadAllPhotos
		} = this.props;
		const { emailInput, emailError, showDownloadForm, shareDetail } = this.state;
		const hasDownloadPin = shareDetail && shareDetail.downloadPin;
		var title = 'Event Settings';
		return (
			<Modal
				// className="event-settings-model"
				title={title}
				open
				okText="Save"
				// onOk={async () => {
				// 	try {
				// 		if (event.isPrivateFaces !== this.state.isPrivateFaces) {
				// 			await updateEventBoolKey(
				// 				userId,
				// 				event.id,
				// 				'isPrivateFaces',
				// 				this.state.isPrivateFaces
				// 			);
				// 		}

				// 		if (event.isRegistrationRequired !== this.state.isRegistrationRequired) {
				// 			await updateEventBoolKey(
				// 				userId,
				// 				event.id,
				// 				'isRegistrationRequired',
				// 				this.state.isRegistrationRequired
				// 			);
				// 		}

				// 		if (event.isGuestUploadEnabled !== this.state.isGuestUploadEnabled) {
				// 			await updateEventBoolKey(
				// 				userId,
				// 				event.id,
				// 				'isGuestUploadEnabled',
				// 				this.state.isGuestUploadEnabled
				// 			);
				// 		}

				// 		// Only update max photos if guest upload is enabled and the value has changed
				// 		if (
				// 			this.state.isGuestUploadEnabled &&
				// 			this.state.newGuestMaxphotos !== undefined &&
				// 			this.state.newGuestMaxphotos !== event.guestMaxPhotos
				// 		) {
				// 			await updateNewGuestMaxPhotos(userId, event.id, this.state.newGuestMaxphotos);
				// 		}

				// 		if (this.state.isEventDisabled !== event.isEventDisabled) {
				// 			await updateEventBoolKey(
				// 				userId,
				// 				event.id,
				// 				'isEventDisabled',
				// 				this.state.isEventDisabled
				// 			);
				// 		}

				// 		if (this.state.allowEnquiry !== event.allowEnquiry) {
				// 			await updateEventBoolKey(userId, event.id, 'allowEnquiry', this.state.allowEnquiry);
				// 		}

				// 		onComplete(true);
				// 	} catch (e) {
				// 		onComplete(false);
				// 		message.error(e?.response?.body?.message);
				// 	}
				// }}
				onOk={this.handleSave}
				onCancel={() => onComplete(false)}>
				<div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start' }}>
					{event.hasOwnProperty('facesEnabledAt') && event.facesEnabledAt !== null && (
						<>
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexFlow: 'row',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}>
								<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
									Face-recognition based Privacy
								</div>
								<Switch
									checked={this.state.isPrivateFaces}
									onChange={(checked) => {
										this.setState({ isPrivateFaces: checked });
									}}
								/>
							</div>
							<p style={{ marginBottom: '12px' }}>
								Guests can view only their photos by clicking a selfie. No access to others' photos.
							</p>
							<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
								<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
							</div>
						</>
					)}
					{this.state.isPrivateFaces === true && event.isFacesSupported === true && (
						<>
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexFlow: 'row',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}>
								<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
									PIN to view All photos from this Private Event
								</div>
							</div>

							<div
								className="share-copy-wrapper"
								style={{
									marginTop: '6px',
									marginBottom: '4px',
									width: '100%',
									backgroundColor: '#F4F4F5'
								}}
								onClick={() => this.copyToClipBoard(3, this.state?.shareDetail?.downloadPin)}>
								<span style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 400 }}>
									{this.state?.shareDetail?.downloadPin}
								</span>
								{this.state.copied === 3 ? (
									<div className="share-copy copied">Copied!</div>
								) : (
									<div className="share-copy" style={{ color: '#6F4898', fontWeight: 600 }}>
										COPY
									</div>
								)}
							</div>
							<p style={{ marginBottom: '12px' }}>
								Share this PIN only with users who should see All the Photos from this Event
							</p>
							<div style={{ width: '100%', marginTop: '8px', marginBottom: '16px' }}>
								<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
							</div>
						</>
					)}
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexFlow: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginTop: '8px'
						}}>
						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
							Guest Registration for this Event
						</div>
						<Switch
							checked={this.state.isRegistrationRequired}
							onChange={(checked) => {
								this.setState({ isRegistrationRequired: checked });
							}}
						/>
					</div>
					<p style={{ marginBottom: '5px' }}>
						Guests must enter their email ID and mobile number to access the photos for this event.
					</p>

					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexFlow: 'row',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}>
						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>Guest Uploads</div>
						<Switch
							checked={this.state.isGuestUploadEnabled}
							onChange={(checked) => {
								this.setState({ isGuestUploadEnabled: checked });
							}}
						/>
					</div>
					<p style={{ marginBottom: '5px' }}>If Toggeled users can upload photos to this event</p>

					{this.state.isGuestUploadEnabled == true && (
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexFlow: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}>
							<p style={{ marginBottom: '12px' }}>Edit number of photos for Guests to upload</p>
							{this.error()}
							<InputNumber
								type="number"
								min={event.maxPhoto > 0}
								// max={event.maxPhotos}
								defaultValue={event.guestMaxPhotos}
								value={this.state.newGuestMaxphotos}
								onChange={(value) => {
									this.setState({ newGuestMaxphotos: value });
								}}
							/>
						</div>
					)}
					<p style={{ marginBottom: '5px' }}></p>

					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexFlow: 'row',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}>
						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>Disable Event</div>
						<Switch
							checked={this.state.isEventDisabled}
							onChange={(checked) => {
								this.setState({ isEventDisabled: checked });
							}}
						/>
					</div>
					<p style={{ marginBottom: '5px' }}>
						If Toggeled Event will be no longer visible to users
					</p>

					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
					</div>

					{/* Add Allow Enquiry setting */}
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexFlow: 'row',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}>
						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>Allow Enquiry</div>
						<Switch
							checked={this.state.allowEnquiry}
							onChange={(checked) => {
								this.setState({ allowEnquiry: checked });
							}}
						/>
					</div>
					<p style={{ marginBottom: '5px' }}>
						Allow event viewers to contact you about your products and services (generate leads)
					</p>

					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
					</div>

					{hasDownloadPin && (
						<>
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexFlow: 'row',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}>
								<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
									Download / Send All Photos
								</div>

								<Button
									icon={<Download />}
									onClick={() => this.setState({ showDownloadForm: !showDownloadForm })}
								/>
							</div>

							<p style={{ marginBottom: '10px' }}>send download link to clients/users</p>

							{showDownloadForm && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginTop: '16px'
									}}>
									<Input
										type="email"
										value={emailInput}
										onChange={this.handleEmailChange}
										placeholder="Enter your email"
										style={{ marginRight: '8px', width: '240px' }}
									/>
									<Button
										type="primary"
										onClick={() => {
											const isValidEmail = this.validateEmail(emailInput);
											if (isValidEmail) {
												downloadAllPhotos(event.id, emailInput, shareDetail.downloadPin);
											}
										}}
										disabled={emailError}>
										Download
									</Button>
								</div>
							)}
							{emailError && (
								<p style={{ color: 'red', textAlign: 'center', marginTop: '8px' }}>{emailError}</p>
							)}
						</>
					)}

					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
					</div>

					<div style={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}>
						<Checkbox
							checked={this.state.isDownloadOff}
							onChange={(e) => this.setState({ isDownloadOff: e.target.checked })}>
							Turn off photo downloads
						</Checkbox>
					</div>

					<div style={{ width: '100%', marginBottom: '8px' }}>
						<Checkbox
							checked={this.state.isDownloadMyPhotosOff}
							onChange={(e) => this.setState({ isDownloadMyPhotosOff: e.target.checked })}>
							Turn off My Photos downloads
						</Checkbox>
					</div>

					<p style={{ marginBottom: '20px' }}></p>
				</div>
			</Modal>
		);
	}
}

EventSettings.propTypes = {
	userId: PropTypes.string.isRequired,
	event: PropTypes.object.isRequired,
	updateEventBoolKey: PropTypes.func.isRequired,
	updateNewGuestMaxPhotos: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	fetchShareDetail: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		userId: state.auth.userId,
		event: state.pouchDB.visibleEvent
	};
}

const mapDispatchToProps = (dispatch) => ({
	fetchShareDetail: async (eventDocId) => {
		return await Event.fetchShareDetail(eventDocId);
	},

	updateEventBoolKey: async (userId, eventId, key, value) => {
		await Event.editEventBoolKey(userId, eventId, key, value);
		let event = await Event.fetchEvent(eventId);
		return await dispatch(refreshVisibleEvent(event));
	},
	updateNewGuestMaxPhotos: async (userId, eventId, value) => {
		await Event.editEventGuestMaxPhotos(userId, eventId, value);
		let event = await Event.fetchEvent(eventId);
		return await dispatch(refreshVisibleEvent(event));
	},
	downloadAllPhotos: async (eventId, email, pin) => {
		try {
			const result = await Event.downloadAllPhotos(eventId, email, pin);
			if (result.message) {
				message.error(result.message);
			} else {
				message.success(result);
			}
		} catch (err) {
			message.error('Something went wrong');
		}
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(EventSettings);
